/**
  * Import the external and internal scripts
  */
import * as $ from 'jquery';

jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};
    
/**
  * Run Init
  */
$(document).ready(function() {
	init();
});

$(window).on('resize', function(){
    spacer();
});


function init() {
    header();
    // splashAnimation();
}

/**
  * Spacer
  */
function spacer() {
    var headerHeight = $('.header').outerHeight();
    if ($('.spacer').length) {
        $('.spacer').css('padding-bottom', headerHeight);
    }
}

/**
  * Header scripts including the side nav and scrollable functionality
  */
function header() {
    const header = $('.header');
    const burger = $('.header__menu');
    const logo = $('.header__logo');
    const menu = $('.nav');
    const subMenu = $('.sub-menu')
    const menuClose = $('.js-close-menu');
    const subMenuClose = $('.close-sub-menu')
    const contact = $('.header__contact');
    const main = $('main');
    let menuOpen = false;

    function menuToggle() {
        if( !menuOpen && !burger.hasClass('will-remove-project-planner') ) {
            menu.addClass('is-open');
            menuOpen = true;
            $('.blackout').addClass('is-active');
            if ($(window).width() <= 1024) {
                $('body').addClass('is-overflow-h');
            }
        } else {
            menu.removeClass('is-open');
            subMenuToggle();
            menuOpen = false;
            $('body').removeClass('is-overflow-h');
            $('.blackout').removeClass('is-active');
            $('.sub-menu').removeClass('is-open');
        }
    }

    function subMenuToggle(item) {
        $(item).closest('.sub-menu').removeClass('is-open');
        $('.nav__content ul').removeClass('has-sub-open');
    }

    var lastScrollTop = 0;
    let timeout = null;
    
    $(window).on('scroll load', function() {
        const currentPosition = $(this).scrollTop();

        if( currentPosition >= 40 ) {
            header.addClass('is-scrolled');
        } else {
            header.removeClass('is-scrolled');
        }
        
        contact.removeClass('is-stopped');

        if (timeout !== null) {
            clearTimeout(timeout);        
        }
        timeout = setTimeout(function() {
            contact.addClass('is-stopped');
        }, 1000);

        if ($('body').hasClass('single-projects')) {
            var heroHeight = $('.project-hero').outerHeight();
            if( currentPosition >= heroHeight ) {
                $(logo).addClass('show-tease');
            } else {
                $(logo).removeClass('show-tease');
            }

        }

        lastScrollTop = currentPosition;
    });

    burger.on('click', function() {
        menuToggle();
    });

    menuClose.on('click', function () {
        menuToggle();
    });


    subMenuClose.on('click', function () {
        subMenuToggle($(this));
    });

    // When you click on a menu item with children, find its sub menu and open it.
    $('.menu-item--has-children > a').on('click', function(e) {
        e.preventDefault();
        $(this).parent().find('.sub-menu').addClass('is-open');
        $(this).closest('ul').addClass('has-sub-open');
    });

    // Remove the blackout when you click on blackout space
    $('.blackout').on('click', function(){
        menuToggle();
    });

    // Fun hover effects
    $( '.nav__content ul li a' ).hover(
        function() {
            $( this ).closest('.nav__content').find('ul').addClass( 'is-hovered' );
        }, function() {
            $( this ).closest('.nav__content').find('ul').removeClass( 'is-hovered' );
        }
    );

    // When you click a dead link, do nothing. This class needs to be added into WP Menu Editor
    $('.js-no-link > a').on('click', function(e){
        e.preventDefault();
    });

    $('.grand-children a').on('click', function(){
        if ($(window).width() <= 1024 && $(this).attr('href').indexOf('#') != -1) {
            menuToggle();
        }
    });
}

/**
  * Only play the videos when they're in view. Not for performance, just so people can see the whole thing
  */
function playVideosWhenInView() {
   
    $(window).on('resize scroll', function() {

      $('video:not(.disable-autoplay)').each(function() {

        if ($(this).isInViewport()) {
            if (!$(this).hasClass('video-has-finished')) {
                $(this).trigger('play');
            }
        } else {
            $(this).trigger('pause');
            $(this).removeClass('video-has-finished');
        }

      });

    });

    $("video").bind("ended", function() {
        $(this).addClass('video-has-finished');
    });

}

/**
  * The beautiful splash animation on load on the homepage
  */
function splashAnimation() {

    if ($('.splash').length > 0 && $('body').hasClass('home')) {

        setTimeout(function() {

            anime.timeline({loop: false})
            .add({
                targets: '.logo-wrap',
                opacity: 1,
                easing: 'easeInOutCubic',
                duration: 300,
                delay: 0
            }).add({
                targets: 'circle.logo-stroke',
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutCubic',
                duration: 600,
                delay: 500
            }).add({
                targets: 'circle.logo-stroke--alt',
                opacity: [0,1]
            }).add({
                targets: '.logo-plane',
                opacity: [0,1],
                translateX: [-30,4],
                translateY: [30,4],
                scale: [0,1],
                duration: 500,
                offset: '-=1200'
            }).add({
                targets: '.logo-wrap',
                opacity: 0,
                duration: 1000,
                easing: "easeOutExpo",
                delay: 500
            }).add({
                targets: '.splash',
                opacity: 0,
                duration: 500,
                easing: "easeInOutCubic",
                delay: 0,
                offset: '-=800',
                complete: function(anim) {
                    $('.splash').addClass('is-hidden');
                    loadInSplashWord();
                    $('.home-hero .heading-block').css('opacity', 1);
                }
            });

        }, 0);

    }



    // var words = $('.home-hero .heading-block__heading').text().split(' ');
    // $('.home-hero .heading-block__heading').empty();
    // $(words).each(function(i, v) {
    //     $('.home-hero .heading-block__heading').append($('<span class="home-hero-letter">').html( v + '&nbsp;'));
    // });

    if ($('body').hasClass('home')) {
        setTimeout(function() {
            if ($('.splash').length) {
                // If new visitor, do nothing as the splash is playing
            } else {
                loadInSplashWord();
                $('.home-hero .heading-block').css('opacity', 1);
            }
        }, 500);
    }

    function resizeH1Pseudo() {
        var spanHeight = $('.home-hero-letter').outerHeight();
        var height = spanHeight - 8;
        $( "<style type='text/css'>.home-hero h1:after{ height: "+height+"px; }</style>" ).appendTo( "head" )
    }

    function loadInSplashWord() {
        resizeH1Pseudo();

        $('.home-hero-letter:nth-of-type(-n+5)').addClass('show-me');
        
        setTimeout(function() {
            $('.home-hero-letter:nth-of-type(n+6)').addClass('show-me');
            setTimeout(function() {
                $('.home').find('main').addClass('is-finished-animation');
            }, 250);
        }, 150);
    }
}

function modal() {
    let header = $('.header')
    const bodyEl = $('body')
    const modal = $('.modal')
    const main = $('main')
    const modalToggle = $('.modal-toggle')
    const modalVideo = $('.modal__video')
    let video = $('.video_embed')

    function closeModal() {
        main.removeClass('is-modal-open')
        bodyEl.removeClass('overflow-hidden')
        modal.removeClass('modal--visible')
        video.css('display', 'none')
        header.removeClass('is-hidden')
        video.trigger('pause')
        video[0].currentTime = 0
    }

    $(document).keyup((e) => {
        if (e.key === 'Escape' || e.key === 'Esc') {
            closeModal()
        }
    })

    modalToggle.on('click', (e) => {
        e.preventDefault()
        main.addClass('is-modal-open')
        header.addClass('is-hidden')
        bodyEl.addClass('overflow-hidden')
        modal.toggleClass('modal--visible')
        video.css('display', 'block')
        video.trigger('play')
    })

    $('.modal__main, .modal__mask, .modal__close').on('click', () => {
        closeModal()
    })

    modalVideo.on('click', (e) => {
        e.stopPropagation()
    })
}


